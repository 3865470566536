import React, {Component} from 'react'
import rain from '../../assets/weather/rain.svg';
import * as moment from 'moment';

export default class Rain extends Component{

   
    momentRelativeTime = (date) => moment(date).fromNow();

    dateTimeFormater = (date1) => {

        if (moment(date1).isValid()) {
            let propDay = moment(date1).utcOffset(0).format("MMMM Do YYYY")
            let currDay = moment().utcOffset(0).format("MMMM Do YYYY")

            console.log(moment(date1).utcOffset(0).format("MMMM Do YYYY"))
            if(propDay === currDay){
                return this.momentRelativeTime(moment(this.props.data.created).utcOffset(0).format('YYYY-MM-DD[T]HH:mm:ss'))
            }
            else{
                return moment(date1).utcOffset(0).format("dddd, MMMM Do YYYY, HH:mm:ss");                
            }
        }
        return '';
    }

    render(){
        return(
            <div className="weathercards h-100 w-100 dropshadow rounded-1 p-sm-3 p-2">
                <div className="lastupdate">Last Updated :<br className="d-sm-none"/> {this.dateTimeFormater(this.props.data.created)}</div>
                    <div className="cardbody col-12 d-flex p-md-4 p-sm-3 p-1">
                        <div className="col-5 d-flex justify-content-center">
                            <img className="align-self-center" src={rain}/>
                        </div>
                        <div className="col-7 align-self-center">
                            <h1 style={{color: "#1b75bc"}}>{this.props.data.value}<sup>mm</sup></h1>
                        </div>
                    </div>
                <h5 className="col-12">Rain</h5>
            </div>
        )
    }
}
