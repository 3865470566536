import React, { Component } from 'react';
import { getAqiDevices } from '../../shared/services/events'
import Loader from '../../components/loader1';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { HashLink as Link } from 'react-router-hash-link';

class BarrageTable extends Component {
    constructor(){
        super();
        this.state ={
            loading:true,
            locationDetails:[],
        }
    }
    loadData = async () =>{
        if(this.props.filterHub.length > 0 && this.state.loading){
            this.setState({
                loading:false
            })
            let location =[]
            this.props.filterHub.map((e) => {
                let name=''
                let upValue=0
                let downValue=0
                let temp=e.name.split(' ')
                if(location.indexOf(temp[0]) == -1){
                    location.push(temp[0])
                    name=temp[0]+' Barrage'
                    this.props.filterHub.map((t) => {
                        let temp=t.name.split(' ')
                        if(location.indexOf(temp[0]) !== -1 ){
                            if(temp[2] == 'Upstream'){
                                upValue = t.value
                            }
                            else{
                                downValue = t.value
                            }
                        }
                    })
                    this.state.locationDetails.push({
                        name,
                        upValue,
                        downValue
                    })
                }
            })
        }
    }
    render() {
        const renderTooltip = props => (
            <Tooltip {...props} className="tooltip-top">Tooltip for the register button</Tooltip>
        );
        this.loadData()
            return (
                <div style={{height: '500px', fontSize: '16px'}}>
                    <div className="col-12 d-flex p-2 align-self-center" style={{fontWeight: 'bold', height: '50px', boxShadow: '0 5px 10px -5px rgba(0,0,0,0.1)'}}>
                        <span className="col p-2" style={{width:'75%'}}>Location</span>
                        {/* <span className="py-2" style={{width:'25%'}}>Stream</span> */}
                        <span className="p-2 d-flex" style={{width:'25%'}}>Feet
                            {/* <OverlayTrigger placement="top" overlay={renderTooltip}>
                                <p className="text-right ml-3 rounded-circle" id="infohover" style={{fontFamily:'Brush Script MT',fontSize: '20px', color:'grey', lineHeight: '20px', cursor: 'pointer', padding: '0px 7px 0px 6px', height: '20px', border: '1px solid grey'}}>i</p>
                            </OverlayTrigger> */}
                        </span>
                    </div>
                    <div style={{overflowY:'auto', height: '450px'}}>
                        <div className="d-table p-2 w-100">
                            {(this.state.locationDetails.length > 0 && !this.state.loading)?
                                this.state.locationDetails.map((e) =>
                                    <Nauman data= {e}/>
                                )
                            :<Loader/>}
                        </div>
                    </div>
                </div>  
          );
    
    }
}

export default BarrageTable;

export class Nauman extends Component{
    render(){
        return(
            // <div className="col-12 w-100 d-table-row align-middle">
            //     <span className="d-table-cell px-2 py-0 align-middle" style={{borderBottom: '1px solid #00000015',width:'50%'}}> <Link to="#location_graphs"> {this.props.data.name} </Link></span>
            //     <span className="d-table-cell p-0 align-middle" style={{borderBottom: '1px solid #00000015',width:'25%'}}>
            //             <div className="bg-light py-2 px-2 m-0"> <i className="fas fa-arrow-alt-circle-up" style={{color: 'limegreen'}}></i> Up</div>
            //         <div className="py-2 px-2 m-0"> <i className="fas fa-arrow-alt-circle-down" style={{color: 'red'}}></i> Down</div>
            //     </span>
            //     <span className="d-table-cell p-0 align-middle" style={{borderBottom: '1px solid #00000015',width:'25%'}}>
            //         <div className="bg-light py-2 px-2 m-0">{this.props.data.upValue}</div>
            //         <div className="py-2 px-2 m-0">{this.props.data.downValue}</div>
            //     </span>
            // </div>
            // <Link to="#location_graphs">  </Link>
            <React.Fragment>
            <div className="col-12 w-100 d-table-row">
                <span className="d-table-cell px-2 py-4 align-middle" style={{borderBottom: '1px solid #00000015',width:'75%'}}><b>{this.props.data.name}</b> Upstream</span>
                <span className="d-table-cell p-0 py-4 align-middle bg-light" style={{borderBottom: '1px solid #00000015',width:'25%'}}>
                    <div className="py-2 px-2 m-0">{this.props.data.upValue}</div>
                </span>
            </div>
            <div className="col-12 w-100 d-table-row">
                <span className="d-table-cell px-2 py-4 align-middle" style={{borderBottom: '1px solid #00000015',width:'75%'}}><b>{this.props.data.name}</b> Downstream</span>
                <span className="d-table-cell p-0 py-4 align-middle bg-light" style={{borderBottom: '1px solid #00000015',width:'25%'}}>
                    <div className="py-2 px-2 m-0">{this.props.data.downValue}</div>
                </span>
            </div>
            </React.Fragment>
        )
    }
}