// import React from 'react';
import React, { useState } from 'react';

import { Carousel } from 'react-bootstrap'

const Description = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <div className="gradient-primary p-5 my-0 text-light" style={{ textAlign: 'center', fontSize: '16px'}}>
            <h1 className="AQIHeading" style={{ marginBottom: '20px' }}>Barrage</h1>
            <Carousel activeIndex={index} onSelect={handleSelect} controls={false}>
                <Carousel.Item className="pb-5">
                    <q>River and Canal Telemetry is an important aspect of modern day irrigation systems. This data is useful in predicting floods and establishing subsequent flood warning systems as well as for monitoring discharge volumes all around the year.</q>
                    {/* <p className="author text-light">- wikipedia</p> */}
                </Carousel.Item>
                <Carousel.Item className="pb-5">
                    <q>Pakistan is facing water crisis and it is expected that there will be a major crisis in 2025, most of the experts opine that it is the inefficient distribution rather than the availability of water that is an issue. These systems help us tap the distribution in real time.</q>
                    {/* <p className="author text-light">- wikipedia</p> */}
                </Carousel.Item>
            </Carousel>
        </div>
    );
};

export default Description;