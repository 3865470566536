import React, { Component } from 'react'
import illuminance from '../../assets/weather/illuminance.svg';
import * as moment from 'moment';

export class Illuminance extends Component{

    momentRelativeTime = (date) => moment(date).fromNow();

    dateTimeFormater = (date1) => {

        if (moment(date1).isValid()) {
            let propDay = moment(date1).utcOffset(0).format("MMMM Do YYYY")
            let currDay = moment().utcOffset(0).format("MMMM Do YYYY")

            console.log(moment(date1).utcOffset(0).format("MMMM Do YYYY"))
            if(propDay === currDay){
                return this.momentRelativeTime(moment(this.props.data.created).utcOffset(0).format('YYYY-MM-DD[T]HH:mm:ss'))
            }
            else{
                return moment(date1).utcOffset(0).format("dddd, MMMM Do YYYY, HH:mm:ss");                
            }
        }
        return '';
    }

    render(){
        return(
            <div className="weathercards h-100 w-100 dropshadow rounded-1 trans-0-1 p-sm-3 p-2">
                <div className="lastupdate">Last Updated :<br className="d-sm-none"/> {this.dateTimeFormater(this.props.data.created)}</div>
                    <div className="cardbody col-12 d-flex p-md-4 p-sm-3 p-1">
                        <div className="col-4 d-flex justify-content-center p-sm-auto p-2">
                            <img className="align-self-center" src={illuminance}/>
                        </div>
                        <div className="col-8 align-self-center">
                            <h1 style={{color: "#ff9900"}}>{this.props.data.value}<sup>klux</sup></h1>
                        </div>
                    </div>
                <h5 className="col-12">Illuminance</h5>
            </div>
        )
    }
}