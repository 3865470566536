import React, { Component } from 'react';

// Images

import TempImage from '../../images/Aqi-icons/temp2.png'
import AQIGood from '../../images/Aqi-icons/Aqi-Good.png'
import AQIModerate from '../../images/Aqi-icons/Aqi-Moderate.png'
import AQIUnhealthy from '../../images/Aqi-icons/Aqi-Unhealthy.png'
import AQIVeryUnhealthy from '../../images/Aqi-icons/Aqi-VeryUnhealthy.png'
import AQIHazardous from '../../images/Aqi-icons/Aqi-Hazardous.png'

import { getAtmosphereOverview } from '../../shared/services/events'

class HeaderTab extends Component {
    constructor(props) {
        super(props)

        this.state = {
            aqi: null,
            aqiImage: null,
            temperature: null
        }
    }

    async componentDidMount() {
        let Aqi = await getAtmosphereOverview('Karachi', 'AQI');
        if (!Aqi.error) {
            let image = null;

            if (Aqi.value <= 50) {
                image = AQIGood
            } else if (Aqi.value > 50 && Aqi.value <= 100) {
                image = AQIModerate
            } else if (Aqi.value > 100 && Aqi.value <= 200) {
                image = AQIUnhealthy
            } else if (Aqi.value > 200 && Aqi.value <= 300) {
                image = AQIVeryUnhealthy
            } else if (Aqi.value > 300) {
                image = AQIHazardous
            }

            this.setState({
                aqi: Aqi.value,
                aqiImage: image
            })
        }


        let Temp = await getAtmosphereOverview('LinkedThings_AirQuality', 'Temperature');
        if (!Temp.error) {
            this.setState({
                temperature: Temp.value
            })
        }
    }

    render() {
        return (
            <div style={{ margin: '0 auto' }}>
                {(this.state.aqi !== null && this.state.temperature !== null) ?
                    <div className="bodyAirvisualWidget">
                        <div className="itemAirvisualWidget">
                            <img src={this.state.aqiImage} className="rounded-icon" />
                            <div className="AirvisualWidgetItemText">
                                <strong className="Text">{this.state.aqi}</strong>
                                <br /> Karachi AQI
                            </div>
                        </div>
                        <div className="dividerAirvisualWidget"></div>
                        <div className="itemAirvisualWidget">
                            <img src={TempImage} className="rounded-icon" />
                            <div className="AirvisualWidgetItemText">
                                <strong className="temp">{this.state.temperature}</strong>
                                <br /> Temperature
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        );
    }
}

export default HeaderTab;