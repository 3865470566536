import React, { Component } from 'react';
import loader from '../assets/loader.svg'

class Loader extends Component {

    render(){
        return(
            <div className="p-5 w-100 h-100 d-flex"><img className="align-self-center w-100" style={{height:"120px"}} src={loader}/></div>
        );
    }
}

export default Loader