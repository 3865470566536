import axios from 'axios';

const instance = axios.create({
    // baseURL: 'https://d28f1ea282c4.ngrok.io/api/v1',
    baseURL: 'https://gateway.s5.ottomatically.com/api/v2',
    // baseURL: 'https://api.ottomatically.com/api/v2',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

export default instance;