// import React from 'react';
import React, { useState } from 'react';

import { Carousel } from 'react-bootstrap'

const Description = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <div className="gradient-primary p-5 my-0 text-light" style={{ textAlign: 'center', fontSize: '16px'}}>
            <h1 className="AQIHeading" style={{ marginBottom: '20px' }}>Linked Things Data Service</h1>
            {/* <Carousel activeIndex={index} onSelect={handleSelect} indicators={false} arrows={false}>
                <Carousel.Item> */}
                    <q>Linked Things provides IoT & AI Platform for Emerging Markets. We have a unique approach to create adoption of emerging technologies in emerging markets. While most of our data is private and securely stored and limited access, we contacted our customers and partners and agreed to make some public interest data public.</q>
                    {/* <p className="author text-light">- wikipedia</p> */}
                {/* </Carousel.Item>
            </Carousel> */}
        </div>
    );
};

export default Description;