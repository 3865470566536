import React, { Component } from 'react';
import OverviewMap from './maps';
import { getEventsByLevel } from '../../shared/services/events';
import WaterLevelGraph from '../../components/waterLevelGraph/waterLevelGraph';
import BarrageTable from '../tables/barrageTable'
import { events } from '../../config';
import Loader from '../../components/loader1'


class water extends Component {
    constructor(props) {
        super(props);

        this.state = {
            org_name:'UCPCASW',
            data: null,
            events: null,
            pathname: "/organizations/UCPCASW/overview",
            filterHub:[],
        };
    }
    loadEvents= async () => {
        if (this.props.data !== null && this.state.data == null) {
            let obj = { _id: '', levels: [], hubs: [], devices: [], events:[] }
            let hubs = [], levels = [], devices = [], events = []
    
            let production = '';
            let disableSchedule = '';
    
            devices = this.props.data.devices;
            events=this.props.data.events
            obj = {
                devices,
                production,
                disableSchedule,
                events,
            };
            if (this.props.data.events !== 'Errror') {
                this.props.data.devices.forEach((e)=>{
                    this.props.data.events.devices.forEach((t) => {
                        if(t._id === e._id){
                            e['value']=t.events[0].value
                            e['created']=t.events[0].created
                            this.state.filterHub.push(e)
                        }
                    })
                })
            }
            this.setState({
                data:obj,
            })
        }
    }
    render(){
        this.loadEvents()
        return(
            <div>
                <div className="container">
                    <div className="row rounded my-5 dropshadow p-1">
                        <div className="col-lg-8 col-md-7 col-12 p-2">
                            {this.state.data !== null?
                              <OverviewMap data={this.state.data} filterHub={this.state.filterHub}/>
                              : <Loader/>
                            }
                        </div>
                        <div className="col-lg-4 col-md-5 col-12 p-2">
                                <BarrageTable filterHub={this.state.filterHub}/>
                        </div>
                    </div>
                </div>
                {/* <div className="container justify-content-center d-flex">
                    {this.state.data == null || this.props.data.events == 'Errror'?
                        <div className="row mb-4">
                            <div className='col-12' style={{ fontSize: 24, paddingTop: '12px' }}>
                                Loading...
                            </div>
                        </div>:
                        <div className='row mb-4' >
                            <WaterLevelGraph data={this.state.data} filterHub={this.state.filterHub}/>
                        </div>
                    }
                </div> */}
            </div>

        )

    }
}

export default water