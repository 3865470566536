import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import eventReducer from './reducers/eventReducer'

export default createStore(
    combineReducers({
        eventReducer
    }),
    applyMiddleware(thunk)
)