import React,{ Component } from 'react';
import $ from 'jquery';
import {Link} from 'react-router-dom';
// import Header from '../components/header/header';
// import Footer from '../components/footer';
import Description from '../containers/home-desc/description';
import './home.css';

// var toggle=0;
class Home extends Component {
    read_more(e){
        var div = e.target;
        if($(div).parent().find('p').outerHeight() == 120){
            $(div).html('Show Less').parent().find('p').css({
                'max-height':'600px',
                'display': 'block'
            });
        }else{
            $(div).html('Show More').parent().find('p').css({
                'max-height':'120px'
            }).delay(1000).queue(function (next){
                $(div).html('Show More').parent().find('p').css('display', '-webkit-box');
                next();
            });
        }
    }
    render() {
        document.getElementById('root_title').innerHTML = 'Linked Things'
        console.log("Home")
        return (
            <React.Fragment>
                {/* <Header /> */}
                <Description/>

                <div className="container my-5 card3">
                    <div className="row">
                        <div className="col-12 col-lg-4 col-md-6 mb-3 innercard">
                            <div className="dropshadow" id="air" style={{borderRadius: '20px'}}>
                                <Link to="/airquality/karachi">
                                    <div className="gradient-primary1 py-5 card3-header">
                                        <div className="rounded-circle p-4 my-4 mx-auto img">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="p-2" version="1.1" viewBox="0 0 223 219">
                                            <g>
                                                <path className="air0" d="M111 204l0 0 56 0c1,0 2,-1 3,-1 0,-1 1,-2 1,-3l0 -47 0 -9 0 -125c0,-1 -1,-2 -1,-3 -1,-1 -2,-1 -3,-1l-56 0 0 0 0 -15 0 0 56 0c5,0 10,2 13,6 4,3 6,8 6,13l0 125 0 9 0 47c0,5 -2,10 -6,13 -3,4 -8,6 -13,6l-56 0 0 0 0 -15z"/>
                                                <path className="air1" d="M0 128l1 0c7,0 14,1 26,4 3,1 6,2 10,4l0 15c-1,0 -2,0 -2,0 -5,-2 -9,-3 -13,-5 -9,-2 -15,-3 -22,-3l0 0 0 0 0 0 0 -8 0 -7 0 0zm0 -70l1 0c7,1 14,1 26,5 3,1 6,2 10,3l0 16c-1,0 -2,-1 -2,-1 -5,-1 -9,-3 -13,-4 -9,-3 -15,-3 -22,-4l0 0 0 0 0 0 0 -7 0 -8 0 0zm66 17c4,1 8,1 13,1 5,0 10,-2 16,-4 4,-1 7,-2 10,-3 12,-5 25,-11 41,-10 17,0 34,6 48,11 3,1 5,2 8,3 12,4 16,3 20,3l0 0 0 7 1 8 -1 0c-5,0 -11,1 -25,-4 -2,-1 -5,-2 -8,-3 -12,-4 -29,-10 -43,-11 -13,0 -25,5 -35,9 -4,2 -8,4 -11,5 -7,2 -14,4 -20,4 -5,0 -10,0 -14,-1l0 -15 0 0zm-66 18l1 0c7,1 14,1 26,4 3,1 6,3 10,4l0 16c-1,-1 -2,-1 -2,-1 -5,-2 -9,-3 -13,-4 -9,-3 -15,-4 -22,-4l0 0 0 0 0 0 0 -7 0 -8 0 0zm66 16c4,1 8,2 13,1 5,0 10,-1 16,-3 4,-1 7,-2 10,-4 12,-4 25,-10 41,-10 17,0 34,7 48,12 3,1 5,2 8,2 12,5 16,4 20,3l0 0 0 8 1 7 -1 0c-5,1 -11,1 -25,-3 -2,-1 -5,-2 -8,-3 -12,-5 -29,-11 -43,-11 -13,0 -25,5 -35,9 -4,2 -8,3 -11,4 -7,3 -14,4 -20,4 -5,1 -10,0 -14,0l0 -16 0 0zm0 35c4,1 8,1 13,1 5,0 10,-1 16,-3 4,-1 7,-3 10,-4 12,-5 25,-10 41,-10 17,0 34,7 48,11 3,2 5,2 8,3 12,4 16,4 20,3l0 0 0 8 1 7 -1 0c-5,1 -11,1 -25,-4 -2,0 -5,-1 -8,-3 -12,-4 -29,-10 -43,-10 -13,0 -25,5 -35,9 -4,1 -8,3 -11,4 -7,2 -14,4 -20,4 -5,0 -10,0 -14,-1l0 -15 0 0z"/>
                                                <rect className="air2" y="58" width="223" height="102"/>
                                                <path className="air0" d="M56 0l57 0 0 15 -57 0c-1,0 -2,0 -3,1 -1,1 -1,2 -1,3l0 181c0,1 0,2 1,3 1,0 2,1 3,1l57 0 0 15 -57 0c-5,0 -10,-2 -13,-6 -4,-3 -6,-8 -6,-13l0 -181c0,-5 2,-10 6,-13 3,-4 8,-6 13,-6z"/>
                                                <rect className="air3" x="171" y="35" width="15.0308" height="134.212"/>
                                            </g>
                                            </svg>
                                        </div>
                                    </div>
                                    <h3 className="text-center pt-4"><b>Air Quality</b></h3>
                                    <p className="text-justify px-4 pb-0 pt-3">We realized that 20 million or so people in Karachi do not know what quality of air they are inhaling and how harmful that could be. So in February 2020 we started this project and deployed 11 units across Karachi to start monitoring the air quality. We were kinda lucky to capture pre-lockdown period and immediately recorded positive change in the air quality with the lockdown. Now we have built prediction and analyzed impact of weather, traffic and wind direction on the air quality.</p>
                                </Link>
                                <div onClick={this.read_more} className="readmore px-4 pb-4">Show More</div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 mb-3 innercard">
                                <div className="dropshadow" id="barrage" style={{borderRadius: '20px'}}>
                                    <Link to="/barrage">
                                        <div className="gradient-primary py-5 card3-header">
                                            <div className="rounded-circle p-4 my-4 mx-auto img">
                                                
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 696 437">
                                            <defs>
                                            <linearGradient id="id0" gradientUnits="userSpaceOnUse" x1="633.025" y1="100.702" x2="647.509" y2="100.702">
                                            <stop offset="0" style={{stopOpacity:1, stopColor:'#D2D3D5'}}/>
                                            <stop offset="0.490196" style={{stopOpacity:1, stopColor:'#FEFEFE'}}/>
                                            <stop offset="1" style={{stopOpacity:1, stopColor:'#D2D3D5'}}/>
                                            </linearGradient>
                                            <linearGradient id="id1" gradientUnits="userSpaceOnUse" x1="626.756" y1="449.142" x2="626.758" y2="-5.30921">
                                            <stop offset="0" style={{stopOpacity:1, stopColor:'red'}}/>
                                            <stop offset="1" style={{stopOpacity:1, stopColor:'lime'}}/>
                                            </linearGradient>
                                            </defs>
                                            <g>
                                                <rect className="barrage0" x="21" y="66" width="569" height="305"/>
                                                <path className="barrage1" d="M0 50l611 0 0 32 -611 0 0 -32zm38 77l100 0 0 33 -100 0 0 -33zm437 0l101 0 0 33 -101 0 0 -33zm-146 0l101 0 0 33 -101 0 0 -33zm-145 0l100 0 0 33 -100 0 0 -33z"/>
                                                <path className="barrage2" d="M51 153l74 0 0 227 -74 0 0 -227zm292 0l74 0 0 227 -74 0 0 -227zm146 0l74 0 0 227 -74 0 0 -227zm-292 0l74 0 0 227 -74 0 0 -227z"/>
                                                <path className="barrage3a" d="M95 183c0,-1 1,-2 3,-2 1,0 2,1 2,2l0 65c0,1 -1,2 -2,2 -2,0 -3,-1 -3,-2l0 -65zm443 151c0,-2 1,-3 2,-3 1,0 2,1 2,3l0 41c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -41zm-157 -43c0,-1 1,-2 2,-2 2,0 3,1 3,2l0 54c0,1 -1,2 -3,2 -1,0 -2,-1 -2,-2l0 -54zm-128 -102c0,-1 1,-2 2,-2 1,0 2,1 2,2l0 130c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -130zm-163 102c0,-1 1,-2 2,-2 1,0 2,1 2,2l0 54c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -54z"/>
                                                <path className="barrage3b" d="M503 315c0,-1 1,-2 2,-2 1,0 2,1 2,2l0 54c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -54zm-396 -126c0,-1 1,-2 2,-2 1,0 2,1 2,2l0 130c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -130zm104 -15c0,-1 1,-2 2,-2 1,0 2,1 2,2l0 71c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -71zm25 117c0,-1 1,-2 2,-2 1,0 2,1 2,2l0 54c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -54zm134 -63c0,-1 1,-2 2,-2 2,0 3,1 3,2l0 73c0,1 -1,2 -3,2 -1,0 -2,-1 -2,-2l0 -73zm22 106c0,-2 1,-3 2,-3 2,0 3,1 3,3l0 41c0,1 -1,2 -3,2 -1,0 -2,-1 -2,-2l0 -41zm153 -145c0,-1 0,-2 2,-2 1,0 2,1 2,2l0 130c0,1 -1,2 -2,2 -2,0 -2,-1 -2,-2l0 -130z"/>
                                                <path className="barrage3c" d="M533 183c0,-1 1,-2 2,-2 1,0 2,1 2,2l0 65c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -65zm-432 151c0,-2 1,-3 2,-3 1,0 2,1 2,3l0 41c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -41zm-22 -106c0,-1 1,-2 2,-2 1,0 2,1 2,2l0 73c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -73zm162 -45c0,-1 1,-2 3,-2 1,0 2,1 2,2l0 65c0,1 -1,2 -2,2 -2,0 -3,-1 -3,-2l0 -65zm116 132c0,-1 1,-2 2,-2 1,0 2,1 2,2l0 54c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -54zm0 -141c0,-1 1,-2 2,-2 1,0 2,1 2,2l0 71c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -71zm145 0c0,-1 1,-2 3,-2 1,0 2,1 2,2l0 71c0,1 -1,2 -2,2 -2,0 -3,-1 -3,-2l0 -71zm-291 141c0,-1 1,-2 3,-2 1,0 2,1 2,2l0 54c0,1 -1,2 -2,2 -2,0 -3,-1 -3,-2l0 -54z"/>
                                                <path className="barrage3d" d="M516 228c0,-1 1,-2 2,-2 1,0 2,1 2,2l0 73c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -73zm-451 -54c0,-1 1,-2 2,-2 1,0 2,1 2,2l0 71c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -71zm0 141c0,-1 1,-2 3,-2 1,0 2,1 2,2l0 54c0,1 -1,2 -2,2 -2,0 -3,-1 -3,-2l0 -54zm160 -87c0,-1 1,-2 2,-2 1,0 2,1 2,2l0 73c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -73zm22 106c0,-2 1,-3 2,-3 1,0 2,1 2,3l0 41c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -41zm140 -151c0,-1 1,-2 2,-2 1,0 2,1 2,2l0 65c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -65zm12 6c0,-1 1,-2 2,-2 1,0 2,1 2,2l0 130c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -130zm128 102c0,-1 1,-2 2,-2 1,0 2,1 2,2l0 54c0,1 -1,2 -2,2 -1,0 -2,-1 -2,-2l0 -54z"/>
                                                <g id="_540069264">
                                                    <path className="barrage4" d="M648 0l-15 0 0 406 -1 0c-6,4 -8,8 -8,15 0,4 2,8 5,11 3,3 7,5 11,5 5,0 9,-2 12,-5 2,-3 5,-7 5,-11 0,-7 -3,-11 -9,-15l0 0 0 -406z"/>
                                                    <path className="barrage5" d="M661 11l35 0 0 1 -35 0 0 -1zm0 368l21 0 0 2 -21 0 0 -2zm0 -54l21 0 0 2 -21 0 0 -2zm0 -55l21 0 0 2 -21 0 0 -2zm0 -54l21 0 0 1 -21 0 0 -1zm0 -55l21 0 0 2 -21 0 0 -2zm0 -55l21 0 0 2 -21 0 0 -2zm0 260l21 0 0 2 -21 0 0 -2zm0 -55l21 0 0 2 -21 0 0 -2zm0 -55l21 0 0 2 -21 0 0 -2zm0 -54l21 0 0 2 -21 0 0 -2zm0 -55l21 0 0 2 -21 0 0 -2zm0 -54l21 0 0 1 -21 0 0 -1zm0 259l21 0 0 2 -21 0 0 -2zm0 -55l21 0 0 2 -21 0 0 -2zm0 -54l21 0 0 2 -21 0 0 -2zm0 -55l21 0 0 2 -21 0 0 -2zm0 -54l21 0 0 1 -21 0 0 -1zm0 -55l21 0 0 2 -21 0 0 -2zm0 314l35 0 0 2 -35 0 0 -2zm0 -55l35 0 0 2 -35 0 0 -2zm0 -54l35 0 0 2 -35 0 0 -2zm0 -55l35 0 0 2 -35 0 0 -2zm0 -54l35 0 0 1 -35 0 0 -1zm0 -55l35 0 0 2 -35 0 0 -2zm0 -55l35 0 0 2 -35 0 0 -2zm0 -13l21 0 0 1 -21 0 0 -1zm0 -14l21 0 0 2 -21 0 0 -2zm0 -14l21 0 0 2 -21 0 0 -2z"/>
                                                    <polygon className="barrage6" width="50px" points="648,0 633,0 633,323 648,323 "/>
                                                </g>
                                            </g>
                                            </svg>
                                            </div>
                                        </div>
                                        <h3 className="text-center pt-4"><b>Barrage</b></h3>
                                        <p className="text-justify px-4 pb-0 pt-3">River Indus flows for 3180km, mostly across Pakistan without any digital, realtime, monitoring system. The water and flood levels are recorded manually and dispatched on daily basis. We did a project with USPCAS-W at Mehran University, Jamshoro to install the water level monitors at upstream and downstream sides of both Guddu and Sukkur barrage. Now the data is available on hourly basis. This is important for flood monitoring, water discharge monitoring - this realtime system can actually resolve water distribution disputes between the provinces and even within the provinces.</p>
                                    </Link>
                                    <div onClick={this.read_more} className="readmore px-4 pb-4">Show More</div>
                                </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 mb-3 innercard">
                                <div className="dropshadow" id="weather" style={{borderRadius: '20px'}}>
                                    <Link to="/weather">
                                        <div className="gradient-primary2 py-5 card3-header">
                                            <div className="rounded-circle my-4 mx-auto img">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="p-4" version="1.1"
                                            viewBox="0 0 1030 1284">
                                            <defs>
                                            <linearGradient id="barid0" gradientUnits="userSpaceOnUse" x1="738.892" y1="1125.21" x2="813.417" y2="1301.75">
                                            <stop offset="0" style={{stopOpacity:1, stopColor:"#A8C5FF"}}/>
                                            <stop offset="1" style={{stopOpacity:1, stopColor:"#003399"}}/>
                                            </linearGradient>
                                            <linearGradient id="barid1" gradientUnits="userSpaceOnUse" xlinkHref="#barid0" x1="485.735" y1="1091.26" x2="560.26" y2="1267.8">
                                            </linearGradient>
                                            <linearGradient id="barid2" gradientUnits="userSpaceOnUse" xlinkHref="#barid0" x1="81.5521" y1="1137.34" x2="156.077" y2="1313.88">
                                            </linearGradient>
                                            <linearGradient id="barid3" gradientUnits="userSpaceOnUse" xlinkHref="#barid0" x1="738.892" y1="763.977" x2="813.417" y2="940.515">
                                            </linearGradient>
                                            <linearGradient id="barid4" gradientUnits="userSpaceOnUse" xlinkHref="#barid0" x1="481.569" y1="829.716" x2="556.094" y2="1006.25">
                                            </linearGradient>
                                            <linearGradient id="barid5" gradientUnits="userSpaceOnUse" xlinkHref="#barid0" x1="131.711" y1="784.512" x2="206.236" y2="961.051">
                                            </linearGradient>
                                            <linearGradient id="barid6" gradientUnits="userSpaceOnUse" xlinkHref="#barid0" x1="294.33" y1="997.433" x2="368.855" y2="1173.97">
                                            </linearGradient>
                                            <linearGradient id="barid7" gradientUnits="userSpaceOnUse" x1="291.789" y1="-91.8973" x2="1805.88" y2="1298.05">
                                            <stop offset="0" style={{stopOpacity:1, stopColor:"yellow"}}/>
                                            <stop offset="1" style={{stopOpacity:1, stopColor:"#FF6600"}}/>
                                            </linearGradient>
                                            <linearGradient id="barid8" gradientUnits="userSpaceOnUse" xlinkHref="#barid0" x1="492.631" y1="9.59515" x2="648.474" y2="873.834">
                                            </linearGradient>
                                            </defs>
                                            <g>
                                            <circle className="weather0" cx="684" cy="346" r="346"/>
                                            <circle className="weather1" cx="684" cy="346" r="226"/>
                                            <path className="weather2" d="M137 418l10 0c0,-78 63,-142 141,-142 5,0 10,1 15,1 33,-87 117,-149 216,-149 117,0 213,86 229,199 90,11 160,89 160,182l0 0c0,100 -82,183 -183,183l-12 0 -201 0 -375 0c-75,0 -137,-62 -137,-137l0 0c0,-75 62,-137 137,-137z"/>
                                            <path className="weather3" d="M157 800c1,0 46,61 46,86 0,25 -21,45 -46,45 -25,0 -45,-20 -45,-45 0,-25 45,-86 45,-86z"/>
                                            <path className="weather4" d="M507 845c0,0 45,61 45,86 0,25 -20,45 -45,45 -25,0 -45,-20 -45,-45 0,-25 45,-86 45,-86z"/>
                                            <path className="weather5" d="M765 779c0,0 45,61 45,86 0,25 -20,46 -45,46 -25,0 -46,-21 -46,-46 0,-25 45,-86 46,-86z"/>
                                            <path className="weather6" d="M320 1013c0,0 45,61 45,86 0,25 -20,45 -45,45 -25,0 -45,-20 -45,-45 0,-25 45,-86 45,-86z"/>
                                            <path className="weather7" d="M511 1106c1,0 46,62 46,87 0,25 -21,45 -46,45 -25,0 -45,-20 -45,-45 0,-25 45,-87 45,-87z"/>
                                            <path className="weather8" d="M107 1152c0,0 45,62 45,87 0,25 -20,45 -45,45 -25,0 -45,-20 -45,-45 0,-25 45,-87 45,-87z"/>
                                            <path className="weather9" d="M765 1140c0,0 45,62 45,87 0,25 -20,45 -45,45 -25,0 -46,-20 -46,-45 0,-25 45,-87 46,-87z"/>
                                            </g>
                                            </svg>
                                            </div>
                                        </div>
                                        <h3 className="text-center pt-4"><b>Weather Monitoring</b></h3>
                                        <p className="text-justify px-4 pb-0 pt-3">Linked Things is one of the few providers of reliable weather stations in Pakistan. We have so far deployed 11 weather stations in Pakistan and one of them is installed outside our office in Karachi. As the data grows we are looking at developing our own local forecasting and information dissemination system.</p>
                                    </Link>
                                    <div onClick={this.read_more} className="readmore px-4 pb-4">Show More</div>
                                </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 mb-3 innercard">
                                <div className="dropshadow" id="energy" style={{borderRadius: '20px'}}>
                                    <Link to="/energy">
                                        <div className="gradient-primary3 py-5 card3-header">
                                            <div className="rounded-circle my-4 mx-auto img">
                                                <svg className="p-4" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" version="1.1" viewBox="0 0 2808 4892" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                    <g>
                                                        <path className="energy_fil0" d="M1404 4254l3 0 407 0c73,0 132,59 132,132 0,72 -59,131 -132,131l-408 0 -2 0 -2 0 -408 0c-72,0 -131,-59 -131,-131 0,-73 59,-132 131,-132l407 0 3 0zm0 437c88,0 176,0 235,-3 58,-2 87,-6 108,-18 21,-12 35,-32 46,-41 11,-9 20,-9 25,5 5,13 7,41 0,75 -6,34 -21,75 -44,106 -23,30 -53,50 -76,61 -22,11 -37,14 -46,15 -9,1 -13,1 -53,1 -41,0 -118,0 -195,0l0 -163 0 163c-77,0 -154,0 -195,0 -40,0 -44,0 -53,-1 -9,-1 -24,-4 -46,-15 -23,-11 -53,-31 -76,-61 -23,-31 -37,-72 -44,-106 -6,-34 -5,-62 0,-75 5,-14 14,-14 25,-5 11,9 25,29 46,41 22,12 50,16 109,18 58,3 146,3 234,3zm0 -853l-5 0 -523 0c-73,0 -132,59 -132,132 0,72 59,132 132,132l524 0 4 0 4 0 524 0c73,0 132,-60 132,-132 0,-73 -59,-132 -132,-132l-523 0 -5 0z"/>
                                                        <path className="energy_fil1" d="M1256 3422c148,0 73,-1994 148,-1994 75,0 0,1994 148,1994l0 106 -296 0c0,-35 0,-71 0,-106z"/>
                                                        <path className="energy_fil2" d="M1404 3422l408 0c39,0 38,2 36,-81l0 -2c-2,-70 -5,-162 0,-262 6,-106 20,-220 52,-326 53,-179 153,-324 259,-464 43,-57 61,-84 108,-138 173,-199 277,-460 277,-745 0,-315 -127,-600 -334,-806 -204,-205 -486,-332 -798,-334l-8 0 -8 0c-312,2 -594,129 -798,334 -207,206 -334,491 -334,806 0,291 109,557 289,759 29,33 35,44 56,71 121,157 239,316 299,517 32,106 46,220 52,326 5,100 2,192 0,262l0 2c-2,83 -2,81 36,81l408 0zm0 -3422l9 0c384,2 732,159 984,411 254,254 411,605 411,993 0,349 -127,668 -337,913 -35,40 -54,66 -87,108 -97,127 -188,257 -232,401 -25,84 -36,177 -41,264 -5,92 -2,178 0,242l0 2c5,170 6,225 -46,284 -58,68 -113,68 -253,68l-408 0 -408 0c-140,0 -195,0 -253,-68 -52,-59 -51,-114 -46,-284l0 -2c2,-64 5,-150 0,-242 -4,-87 -16,-180 -41,-264 -46,-154 -147,-290 -251,-425 -26,-34 -45,-57 -74,-92 -207,-244 -331,-560 -331,-905 0,-388 157,-739 411,-993 252,-252 600,-409 984,-411l9 0z"/>
                                                        <g>
                                                            <path className="energy_fil3" d="M2161 1865c-28,48 -537,-210 -753,-374 -218,172 -733,422 -761,374 -28,-49 451,-360 700,-465 -40,-275 1,-846 57,-846 56,0 87,570 53,839 257,102 732,423 704,472z"/>
                                                            <line className="energy_fil4" x1="1401" y1="2302" x2="1407" y2= "2302" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                        <h3 className="text-center pt-4"><b>Energy Monitoring</b></h3>
                                        <p className="text-justify px-4 pb-0 pt-3">Linked Things started with Energy IoT back in 2016 and our power meters and controls were the first set of products to be deployed. Over the years we have been able to not only capture the data but also improve the efficiencies and reduce the power costs across our customer base. We are in a unique position to collect insights from households, businesses, factories, data centers, PMTs and even parking lots. Here we will be presenting our savings calculator for our customers to determine savings that they achieve by deploying IoT solutions on their premises.</p>
                                    </Link>
                                    <div onClick={this.read_more} className="readmore px-4 pb-4">Show More</div>
                                </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 d-lg-inline d-none mb-3 innercard">
                                <div className="dropshadow" id="" style={{borderRadius: '20px'}}>
                                    {/* <Link to="/energy"> */}
                                        <div className="gradient-primary0 py-5 card3-header">
                                            <div className="rounded-circle my-4 mx-auto img">
                                                
                                            </div>
                                        </div>
                                        <h3 className="text-center bg-light m-4"><b> &nbsp;</b></h3>
                                        <p className="text-justify bg-light mx-4 mt-4"> &nbsp;</p>
                                        <p className="text-justify bg-light mx-4 mt-2"> &nbsp;</p>
                                        <p className="text-justify bg-light mx-4 mt-2"> &nbsp;</p>
                                    {/* </Link> */}
                                    <div onClick={this.read_more} className="readmore px-4 pb-4">  &nbsp;</div>
                                </div>
                        </div>
                        <div className="col-12 col-lg-4 col-md-6 d-lg-inline d-none mb-3 innercard">
                                <div className="dropshadow" id="" style={{borderRadius: '20px'}}>
                                    {/* <Link to="/energy"> */}
                                        <div className="gradient-primary0 py-5 card3-header">
                                            <div className="rounded-circle my-4 mx-auto img">
                                                
                                            </div>
                                        </div>
                                        <h3 className="text-center bg-light m-4"><b> &nbsp;</b></h3>
                                        <p className="text-justify bg-light mx-4 mt-4"> &nbsp;</p>
                                        <p className="text-justify bg-light mx-4 mt-2"> &nbsp;</p>
                                        <p className="text-justify bg-light mx-4 mt-2"> &nbsp;</p>
                                    {/* </Link> */}
                                    <div onClick={this.read_more} className="readmore px-4 pb-4">  &nbsp;</div>
                                </div>
                        </div>
                    </div>
                </div>

                {/* <Footer /> */}
            </React.Fragment>
        );
    }

}

export default Home;