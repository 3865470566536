// import React from 'react';
import React, { useState } from 'react';

import { Carousel } from 'react-bootstrap'

const Description = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <div className="gradient-primary3  p-5 my-0 text-black" style={{ textAlign: 'center', fontSize: '16px'}}>
            <h1 className="AQIHeading">Energy</h1>
            <Carousel activeIndex={index} onSelect={handleSelect} controls={false}>
                <Carousel.Item>
                    <div className="d-flex w-100" style={(window.screen.width < 850)?
                        {minHeight:'300px', margin: 'auto', fontSize: '14px'}:
                        {minHeight:'150px', margin: 'auto'}}
                        >
                        <div className="w-100 d-flex align-self-center justify-content-center">
                            <q>Parking Lots are usually made in the basements and are lit almost 18 hours a day. That is a huge waste of energy for a place that is hardly used for most of the day. We have developed a solution that can be deployed quickly and can save you 60-80% on your next electricity bill with ROI of roughly six months.</q>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="d-flex w-100" style={(window.screen.width < 850)?
                        {minHeight:'300px', margin: 'auto', fontSize: '14px'}:
                        {minHeight:'150px', margin: 'auto'}}
                        >
                        <div className="w-100 d-flex align-self-center justify-content-center">
                            <q> Data Centers are unmanned locations but have to maintained for seamless business operations. A glitch or a rise in temperature can stop the data center operations. Our solution maintains the temperatures in the data center and automates the scheduling of tasks such as switching on and off the air conditioners. This brings our customers with 20-40% savings starting from their next electricity bill.</q>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    );
};

export default Description;