import React, { Component } from "react";
import * as moment from "moment";
import * as functions from "../../functions/functions";
import $ from "jquery";

let heatIValue, heatColor;
export default class FeelsLike extends Component {
  momentRelativeTime = (date) => moment(date).fromNow();

  dateTimeFormater = (date1) => {
    if (moment(date1).isValid()) {
      let propDay = moment(date1).utcOffset(0).format("MMMM Do YYYY");
      let currDay = moment().utcOffset(0).format("MMMM Do YYYY");

      console.log(moment(date1).utcOffset(0).format("MMMM Do YYYY"));
      if (propDay === currDay) {
        return this.momentRelativeTime(
          moment(date1).utcOffset(0).format("YYYY-MM-DD[T]HH:mm:ss")
        );
      } else {
        return moment(date1)
          .utcOffset(0)
          .format("dddd, MMMM Do YYYY, HH:mm:ss");
      }
    }
    return "";
  };
  componentWillMount() {
    if ("humidity" in this.props && "temperature" in this.props) {
      heatIValue = functions.heatIndex({
        temperature: parseInt(this.props.temperature.value),
        humidity: parseInt(this.props.humidity.value),
      });
      let heatR = 0,
        heatG = 255,
        heatB = 0;
      if (heatIValue <= 31) {
        heatR = 240;
        heatG = 210;
        heatB = 90;
      } else if (heatIValue > 31 && heatIValue <= 38) {
        var per = ((heatIValue - 31) / 7) * 100;
        heatR = 240;
        heatG = 240;
        heatB = 90 - (90 * per) / 100;
      } else if (heatIValue > 38 && heatIValue <= 51) {
        var per = ((heatIValue - 38) / 13) * 100;
        heatR = 240;
        heatG = 240 - (138 * per) / 100;
        heatB = 0;
      } else if (heatIValue > 51) {
        var per;
        if (heatIValue <= 60) {
          per = ((heatIValue - 51) / 9) * 100;
        } else {
          per = 100;
        }
        heatR = 240;
        heatG = 102 - (102 * per) / 100;
        heatB = 0;
      }
      heatColor = "rgb(" + heatR + "," + heatG + "," + heatB + ")";
      var heatColorbr = "rgba(" + heatR + "," + heatG + "," + heatB + ",0.5)";
      heatIValue = heatIValue.toFixed(1);
      $(document).ready(function () {
        $("#headIndex").css({
          "background-color": heatColor,
          "border-color": heatColorbr,
          "border-width": "5px",
        });
        // $("#headIndex").css({ color: heatColor });
      });
    }
  }

  render() {
    let size = window.innerWidth;
    console.log(
      "date ",
      this.props.temperature.created,
      this.props.humidity.created
    );
    return (
      <div className="weathercards dropshadow rounded-1 p-3 w-100">
        <div className="lastupdate">
          Last Updated :<br className="d-sm-none" />{" "}
          {/* {this.dateTimeFormater(this.props.data.created)} */}
          {this.props.humidity.created
            ? this.dateTimeFormater(
                // new Date(this.props.temperature.created) >
                //   new Date(this.props.humidity.created)
                //   ? this.props.temperature.created
                //   :
                this.props.humidity.created
              )
            : null}
        </div>
        <div className="row mt-3 justify-content-center">
          <div
            id="temperature"
            className={size < 550 ? "text-right h6 m-0" : "text-right h4 m-0"}
          >
            {this.props.temperature.value}
            <sup>&deg;C</sup>
          </div>
          <div
            style={
              size < 550
                ? {
                    borderLeft: "2px solid rgba(0,0,0,0.04)",
                    transform: "skewX(-28deg)",
                    margin: "0 5px",
                  }
                : {
                    borderLeft: "2px solid rgba(0,0,0,0.04)",
                    transform: "skewX(-28deg)",
                    margin: "0 10px",
                  }
            }
          ></div>
          <div
            className={size < 550 ? "text-left h6 m-0" : "text-left h4 m-0"}
            style={{ color: "rgb(25, 120, 215)" }}
          >
            {this.props.humidity.value}
            <sup>%</sup>
          </div>
          <div
            className="col-12 d-flex justify-content-center"
            style={{ margin: "30px 0" }}
          >
            <div
              id="headIndex"
              className={
                size < 550
                  ? "h5 m-0 text-center rounded-circle"
                  : "h4 m-0 text-center rounded-circle"
              }
              style={
                size < 550
                  ? { width: "60px", lineHeight: "60px" }
                  : { width: "80px", lineHeight: "80px" }
              }
            >
              {heatIValue}
              <sup>&deg;C</sup>
            </div>
          </div>
          <h5 className="w-100 mb-0 text-center">Feels Like</h5>
        </div>
      </div>
    );
  }
}
