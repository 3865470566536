import React from 'react';

import logo1 from "../../images/partners/uspcasw.png"
import { Row, Col } from 'reactstrap';

const DecriptionBelow = () => {
    return (
        <React.Fragment>
            {/* <div style={{ fontSize: '16px' }}>
                Please <a href="https://www.linked-things.com/contact.html" style={{ fontSize: '16px', color: 'blue' }}>contact us</a> in case you would like to install one at your premises.
            </div> */}
            <div className="bg-light p-5 rounded">
                <h1 className="text-center mb-5 text-info"><b>Our Partners</b></h1>
                <Row className="text-center justify-content-center">
                    <Col sm="6" md="6" lg="2" xs="6" xl="2" className="patnerImg">
                        <img src={logo1} alt="lucky star" className="dropshadow p-2"/>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default DecriptionBelow;