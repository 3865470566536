import React, { Component } from 'react';
import phoneIcon from '../../images/icons/phone_icon.svg';
class RequestInfo extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="strip2 gradient-primary1"><a href='https://www.linkedthings.ai/contact'>
                    <div className="container">
                        <div className="d-flex justify-content-center align-self-center">
                            {/* <a href="https://www.linked-things.com/contact"><div className="col-12 px-3 py-3 p-lg-3 mb-md-0 panel3 d-flex text-center"><h3>Please <i><u>click here</u></i> <br className="d-md-none d-inline"/> <span>if you would like to install the service or to get further information</span> </h3></div></a> */}
                            <div className="py-3 p-lg-3 mb-md-0 panel3">
                                <span className="text1">Please click here</span> <br className="d-md-none d-inline"/>
                                <span className="text2">to install the service or</span> <br className="d-md-none d-inline"/> 
                                <span className="text3">to get further information</span>
                            </div>
                        </div>
                    </div></a>
                </div>
            </React.Fragment>
        );
    }
}
export default RequestInfo;