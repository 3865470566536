import React from 'react';

import logo1 from "../../images/partners/logo1.png"
import logo2 from "../../images/partners/logo2.jpg"
import logo3 from "../../images/partners/logo3.png"
import logo4 from "../../images/partners/logo4.jpg"
import logo5 from "../../images/partners/logo5.jpg"
import logo6 from '../../images/partners/logo6.png'
import logo7 from '../../images/partners/logo7.png'
import logo8 from '../../images/partners/logo8.png'
import { Row, Col } from 'reactstrap';

const DecriptionBelow = () => {
    return (
        <React.Fragment>
            {/* <div style={{ fontSize: '16px' }}>
                Please <a href="https://www.linked-things.com/contact.html" style={{ fontSize: '16px', color: 'blue' }}>contact us</a> in case you would like to install one at your premises.
            </div> */}
            <div className="bg-light p-5 rounded">
                <h1 className="text-center mb-5 text-info"><b>Our Partners</b></h1>
                <Row className="text-center justify-content-center">
                    <Col className="col-lg-2 col-md-3 col-6 patnerImg mb-4">
                        <img src={logo6} alt="AVT" className="dropshadow p-2"/>
                    </Col>
                    <Col className="col-lg-2 col-md-3 col-6 patnerImg mb-4">
                        <img src={logo5} alt="Envicrete" className="dropshadow p-2"/>
                    </Col>
                    <Col className="col-md-4 col-12 patnerImg mb-4">
                        <img src={logo7} alt="Consulate General" className="dropshadow p-2" style={{width: '100%'}}/>
                    </Col>
                    <Col className="col-lg-2 col-md-3 col-6 patnerImg mb-4">
                        <img src={logo4} alt="Envicrete" className="dropshadow p-2"/>
                    </Col>
                    <Col className="col-lg-2 col-md-3 col-6 patnerImg mb-4">
                        <img src={logo3} alt="EMCPL" className="dropshadow p-2"/>
                    </Col>
                    <Col className="col-lg-2 col-md-3 col-6 patnerImg mb-4">
                        <img src={logo2} alt="Urban Forest" className="dropshadow p-2"/>
                    </Col>
                    <Col className="col-lg-2 col-md-3 col-6 patnerImg mb-4">
                        <img src={logo1} alt="lucky star" className="dropshadow p-2"/>
                    </Col>
                    <Col className="col-lg-2 col-md-3 col-6 patnerImg mb-4">
                        <img src={logo8} alt="lucky star" className="dropshadow p-2"/>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default DecriptionBelow;