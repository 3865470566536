import React, { Component } from 'react';


import * as config from '../../config';
import { getEventsByLevel} from '../../shared/services/events'

import { Bar, HorizontalBar, Line } from 'react-chartjs-2';
import { Card, Button, CardTitle, CardText, CardHeader, CardBody, Table, Row, Col, Collapse } from 'reactstrap';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import * as functions from '../../functions/functions';

import { connect } from 'react-redux';
import { type } from 'jquery';

const brandInfo = getStyle('--info')

let isValued = false;

class WaterLevelGraph extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            map: true,
            interval: null,
            filterHub: [],
        };
    }

    loadData = () => {
        if (this.state.data == null && this.props.data !== null){  
            this.setState({
                data:this.props.data,
                filterHub: this.props.filterHub,
            })        
        }
    }

    render() {
        this.loadData()
        return (
            <div>
                <div className='row'>
                    {this.state.filterHub.length > 0?
                        this.state.filterHub.map((hvalue,index) =>
                        <div key={index} className='col-12 col-lg-6 col-sm-12 mb-3'>
                            <WaterLevelGraphUI hvalue={hvalue} data={this.props.data}  />
                        </div>
                    )
                    :null
                }
                </div>
            </div>
        );
    }
}

export default WaterLevelGraph;

export class WaterLevelGraphUI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            connection: 0,
            connectionDate: "2:00:00 AM 12/02/2019",

            value: 0,
            valueDate: "N/A",

            loading: false,
            hub: {
                name: null,
                devices: [

                ],

            },
            aqiDevice: null,
            aqiValue: 0,
            connection: 0,
            mqttEvent: null,
            events: { devices: [] },
            devices: null,
            prevEvents: null,
            // aqiBgClass: 'text-white bg-success aq-ov-map-shadow aq-graph',
            aqiBar: {
                labels: [],
                datasets: [

                ],
            },
            aqiBarOptions: {
                tooltips: {
                    enabled: true,
                    /*  custom: CustomTooltips */
                    intersect: true,
                    mode: 'index',
                    position: 'nearest',
                    callbacks: {
                        title: function () {
                            return "";
                        },
                        label: function (item, data) {
                            var datasetLabel = data.datasets[item.datasetIndex].label || "";
                            var dataPoint = item.yLabel;
                            return datasetLabel + ": " + dataPoint + " feet";
                        }
                    }
                },
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                annotation: {
                    annotations: [
                        {

                        }
                    ]
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                color: 'transparent',
                                zeroLineColor: 'transparent',
                            },
                            ticks: {
                                fontSize: 2,
                                fontColor: 'transparent',
                            }
                        }],
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                stepSize: 10,
                                max: props.hvalue.metadata.annotations.max,
                                min: props.hvalue.metadata.annotations.min+50,
                            },
                        }],
                },
                elements: {
                    line: {
                        borderWidth: 1,
                    },
                    point: {
                        radius: 4,
                        hitRadius: 10,
                        hoverRadius: 4,
                    },
                },
            },

        };



    }

    loadEvent= async () => {

        if (this.props.hvalue !== null && this.state.value == 0) {
            
            let graphData = []
            let graphLabel = []

            graphData.push({
                label: 'Current Level',
                value: this.props.hvalue.value
            })
                        
            graphLabel.push(this.props.hvalue.name,)
            this.setState({
                value:1,
                connection: this.props.hvalue.value,
                connectionDate: functions.getTime(this.props.hvalue.created) + ' ' + functions.getDate(this.props.hvalue.created),
                valueDate: functions.getTime(this.props.hvalue.created) + ' ' + functions.getDate(this.props.hvalue.created)
            })
            
            let data = []

            let aqiGraph = this.state.aqiBar;
            if (graphData.length > 0) {
                graphData.map(i => {
                    data.push({
                        label: [i.label],
                        backgroundColor: hexToRgba(brandInfo, 12),
                        steppedLine: true,
                        borderColor: brandInfo,

                        pointHoverBackgroundColor: '#fff',
                        borderWidth: 2,

                        data: [i.value],
                    })
                })
            }
    
            aqiGraph.datasets = data;
            aqiGraph.labels = graphLabel;

            let aqiGraphOptions = this.state.aqiBarOptions;
            aqiGraphOptions.annotation.annotations[0] = {
                events: ['click'],
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: graphData[0].value,
                borderColor: hexToRgba(brandInfo, 0),
                borderWidth: 1.5,
                label: {

                    backgroundColor: brandInfo,
                    fontSize: 12,

                    fontStyle: "520",

                    fontColor: "black",

                    position: "center",
                    enabled: true,

                    content: graphData[0].value + " Feet"
                }
            }

            this.setState({
                aqiBar: aqiGraph,

            })
       }
    }


    render() {
        this.loadEvent()
        return (
            <div className={this.state.aqiBgClass} className="dropshadow h-100 w-100 py-2 m-0 px-0">
                <div className="gradient-primary2 p-3 mx-2 mb-3 text-light rounded text-center">
                    <div>{this.props.hvalue.name}</div>
                </div>
                <div className="chart-wrapper p-1" style={{ height: '350px' }}>
                    <Bar data={this.state.aqiBar} options={this.state.aqiBarOptions} height={100} />
                </div>
                <div className="pb-0" style={{ textAlign: 'center', fontWeight: '400', fontSize: '12px', backgroundColor: 'rgba(0,0,0,0.8)', color: 'white'}}>
                    <div>{"Last Updated: " + this.state.valueDate}</div>
                </div>
            </div>

        );
    }

}
