import React, { Component } from 'react';

import RequestInfo from './strip/request-info';
import Calculator from '../components/energy/calc_div'
import Description from './energy-desc/description'
// import Header from '../components/header/header';
// import Footer from '../components/footer';


class BgContainer extends Component {
    constructor(props) {
        super(props);

    }

    render(){
        return(
            <React.Fragment>
                {/* <Header /> */}
                <Description/>
                <Calculator/>
                <RequestInfo/>
                {/* <Footer /> */}
            </React.Fragment>
        )
    }
}

export default BgContainer;
