import React, { Component } from "react";
import temperature from "../../assets/weather/temperature.svg";
import * as moment from "moment";

export default class Temp extends Component {
  momentRelativeTime = (date) => moment(date).fromNow();

  dateTimeFormater = (date1) => {
    if (moment(date1).isValid()) {
      let propDay = moment(date1).utcOffset(0).format("MMMM Do YYYY");
      let currDay = moment().utcOffset(0).format("MMMM Do YYYY");

      console.log(moment(date1).utcOffset(0).format("MMMM Do YYYY"));
      if (propDay === currDay) {
        return this.momentRelativeTime(
          moment(this.props.data.created)
            .utcOffset(0)
            .format("YYYY-MM-DD[T]HH:mm:ss")
        );
      } else {
        return moment(date1)
          .utcOffset(0)
          .format("dddd, MMMM Do YYYY, HH:mm:ss");
      }
    }
    return "";
  };

  render() {
    return (
      <div className="weathercards h-100 w-100 temperature dropshadow rounded-1 trans-0-1 p-sm-3 p-2">
        <div className="lastupdate">
          Last Updated :<br className="d-sm-none" />{" "}
          {this.dateTimeFormater(this.props.data.created)}
        </div>
        <div className="cardbody col-12 px-0 px-md-3 d-flex">
          <div className="col-2 p-0 d-flex justify-content-center">
            <img src={temperature} />
          </div>
          <div className="col-10 px-md-3 px-2 align-self-center">
            <h6>Celsius</h6>
            <h1 style={{ color: "red" }}>{this.props.data.value}&#176;</h1>
          </div>
        </div>
        <h5>Temperature</h5>
      </div>
    );
  }
}
