import axios from '../utilities/axios';
import cookies from '../utilities/cookies';
import {dataHandler} from '../../functions/functions'

export let getByLevel = async (levelId) => {
    try {
        let token = cookies.getCookie('token');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/levels/' + levelId + '/devices');
        let data = dataHandler(response.data);
        return data;
    } catch(err) {
        return 'Errror';
    }
}

export let getdevicesByEventTypes = async (levelId) => {
    try {
        let token = cookies.getCookie('token');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/devices/LinkedThings_2462abb8d2b_'+levelId+'/events?type=Agri_'+levelId+'_Status&limit=1');
        let data = dataHandler(response.data);
        return data;
    } catch (err) {
        /*  return err.response.data; */
        return 'Errror'
    }
}
