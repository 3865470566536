import moment from "moment";
// import CryptoJS from "./crypto.min.js";
import zlib from "zlib";
import CryptoJS from 'crypto-js';

export function getUnit(deviceId) {
  if (
    deviceId === "CO" ||
    deviceId === "TVOC" ||
    deviceId === "LPG" ||
    deviceId === "CH4" ||
    deviceId === "Humidity" ||
    deviceId === "Oxygen"
  ) {
    return "%";
  } else if (deviceId === "Temperature") {
    return "°C";
  } else if (deviceId === "Pressure") {
    return "hPa";
  } else if (deviceId === "Hydpressure") {
    return "bar";
  } else if (deviceId === "Dust") {
    return "µg/m3";
  } else if (deviceId === "Altitude") {
    return "Meters";
  } else if (deviceId === "AQI") {
    return "INDEX";
  }
}

export function getDate(str) {
  let date = str.split("T")[0];
  let splitDate = date.split("-");
  return splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
}
export function getTime(str) {
  let time = str.split("T")[1];
  let timeFiltered = time.split(".")[0];
  let splitTime = timeFiltered.split(":");
  return formatAMPM(splitTime[0], splitTime[1], splitTime[2]);
}
function formatAMPM(hours, minutes, secs) {
  var hours = hours;
  var minutes = minutes;
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  if (minutes == 0) {
    minutes = "00";
  } else {
    minutes = minutes;
  }
  //  minutes = minutes < 10 ? ''+minutes : minutes;
  var strTime = hours + ":" + minutes + ":" + secs + " " + ampm;
  return strTime;
}

export function formatAMPMHours(hours, minutes, secs) {
  var hours = hours;
  var minutes = minutes;
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  if (minutes == 0) {
    minutes = "00";
  } else {
    minutes = minutes;
  }
  //  minutes = minutes < 10 ? ''+minutes : minutes;
  var strTime = hours + " " + ampm;
  return strTime;
}

export function convertToInt(date, start = null, end = null) {
  let dt = date;

  dt = dt.replace("T", " ");
  if (dt.length === 16) {
    dt += ":00.000";
  } else if (dt.length === 19) {
    dt += ".000";
  } else if (dt.length === 10) {
    dt += " 00:00:00.000";
  }

  return dt;
}

export function convertToDate(str, type) {
  let date = str.split("T")[0];
  let splitDate = date.split("-");
  let time = str.split("T")[1];
  let timeFiltered = time.split(".")[0];
  let splitTime = timeFiltered.split(":");

  let day = splitDate[2];
  let month = splitDate[1];
  let year = splitDate[0];
  let hours = splitTime[0];
  let mins = splitTime[1];
  let secs = splitTime[2];
  if (type == "day") {
    return hours;
  } else if (type == "month") {
    return day;
  } else if (type == "year") {
    return month;
  } else if (type == "live") {
    return formatAMPM(hours, mins, secs);
  } else if (type == "hours") {
    return formatAMPMHours(hours, mins, secs);
  } else if (type == "recent") {
    return hours;
  } else if (type == "date") {
    return day + "-" + month + "-" + year;
  } else if (type == "object") {
    return [year, month, day, hours, mins, secs];
  } else if (type == "compDate") {
    var days = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
    var d = new Date(str);
    var dayName = days[d.getUTCDay()];
    return day + "-" + month + "-" + year + " " + dayName;
  } else if (type == "comp") {
    var days = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
    var d = new Date(str);
    var dayName = days[d.getUTCDay()];
    return formatAMPM(hours, "00", "00") + " " + dayName;
  } else if (type == "compEndDate") {
    return (
      formatAMPM((parseInt(hours) + 1).toString(), "00", "00") +
      " " +
      day +
      "-" +
      month +
      "-" +
      year
    );
  } else if (type == "compTime") {
    var days = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
    var d = new Date(str);
    var dayName = days[d.getUTCDay()];
    let split = formatAMPM(hours, "00", "00").split(":");
    return (
      formatAMPM((parseInt(hours) + 1).toString(), "00", "00") + " " + dayName
    );

    //return formatAMPM(hours, '00', '00') + ' ' + dayName;
    //return formatAMPM(hours, mins, secs)  + ' ' + dayName;
  }

  return formatAMPM(hours, mins, secs) + " " + day + "-" + month + "-" + year;
  //return new Date(year, month, day, hours, mins, secs);
}

export function getState(device, status, sn, graphType) {
  if (device.type == "AC") {
    if (sn == 1) {
      if (status == 0) {
        return "Status : OFF";
      } else {
        return "Status : ON";
      }
    } else if (sn == 2) {
      if (status == 0) {
        return "Swing : OFF";
      } else {
        return "Swing : ON";
      }
    } else {
      return "Temperature : " + " " + status + " °C";
    }
  } else if (
    device.type == "Fan" ||
    device.type == "Light" ||
    device.type === "Monitor"
  ) {
    if (status == 0) {
      return "Status : OFF";
    } else {
      return "Status : ON";
    }
  } else if (device.type == "Door" || device.type == "Lock") {
    if (status == 0) {
      return "Status : CLOSE";
    } else {
      return "Status : OPEN";
    }
  } else if (device.type == "Leakage") {
    if (status == 0) {
      return "Status : NO LEAKAGE";
    } else {
      return "Status : LEAKAGE";
    }
  } else if (device.type == "Curtain") {
    if (status == 0) {
      return "Status : CLOSE";
    } else if (status == 1) {
      return "Status : MID";
    } else {
      return "Status : OPEN";
    }
  } else if (
    device.type == "Sound" ||
    device.type == "Fire" ||
    device.type == "Smoke"
  ) {
    if (status == 0) {
      return "Status : NO " + device.type.toUpperCase();
    } else {
      return "Status : " + device.type.toUpperCase();
    }
  } else if (device.type == "Temperature" || device.type == "Humidity") {
    return (
      device.name +
      ": " +
      status +
      "" +
      (device.type == "Temperature" ? " °C" : " %")
    );
  } else if (device.type == "Motion") {
    if (status == 0) {
      return "Status : NO MOVEMENT";
    } else {
      return "Status : MOVEMENT";
    }
  } else if (device.type == "FuelTank") {
    return "Fuel Level : " + status + " %";
  } else if (device.type == "Battery") {
    return "Battery Level : " + status + " %";
  } else if (device.type == "SourceIdentifier") {
    if (status == 0) {
      return "Status : OFF";
    } else {
      return "Status : ON";
    }
  } else if (device.type == "WaterTank") {
    if (device.metadata) {
      if (device.metadata.monitor) {
        if (device.metadata.monitor === "Oil") {
          return "Oil Level : " + status + " %";
        } else {
          return "Water Level : " + status + " Feet";
        }
      } else {
        return "Water Level : " + status + " Feet";
      }
    } else {
      return "Water Level : " + status + " Feet";
    }
  } else if (device.type == "Moisture") {
    return "Moisture : " + status + " %";
  } else if (device.type == "Pressure") {
    return "Pressure : " + status + " hPa";
  } else if (device.type == "Hydpressure") {
    return "Hydraulic Pressure : " + status + " bar";
  } else if (device.type == "FlowMeter") {
    return "Flow Meter : " + status + " L/min";
  } else if (device.type == "Noise") {
    return "Noise : " + status + " dB";
  } else if (device.type == "Gust" || device.type == "WindSpeed") {
    return device.type + " : " + status + "m/s";
  } else if (device.type == "WindDirection") {
    return device.type + " : " + status + "°";
  } else if (device.type == "Rain") {
    return device.type + " : " + status + " mm";
  } else if (device.type == "Illuminance") {
    return device.type + " : " + status + " LUX";
  } else if (device.type == "UV") {
    return device.type + " : " + status + " UW/cm2";
  } else if (device.type == "SolarRadiation") {
    return device.type + " : " + status;
  } else if (device.type == "DewPoint") {
    return "DewPoint : " + status + " °C";
  } else if (device.type == "PhIndicator") {
    return "Ph : " + status;
  } else if (device.type == "Vibration") {
    if (status == 0) {
      return "Status : NO VIBRATION";
    } else {
      return "Status : VIBRATION";
    }
  } else if (device.type == "Position") {
    if (status == 0) {
      return "Status : NORMAL";
    } else {
      return "Status : TILT";
    }
  } else if (
    device.type === "Dust" ||
    device.type === "CO" ||
    device.type === "CO2" ||
    device.type === "CH4" ||
    device.type === "LPG" ||
    device.type === "TVOC" ||
    device.type === "AQI" ||
    device.type === "Altitude" ||
    device.type === "Oxygen"
  ) {
    if (device.type === "Dust") {
      return device.type + " : " + status + " µg/m";
    } else if (device.type === "AQI") {
      return device.type + " : " + status;
    } else if (device.type === "Altitude") {
      return device.type + " : " + status + " Meters";
    } else {
      return device.type + " : " + status + " %";
    }
  } else if (
    device.type === "PDU" ||
    device.type === "UPS" ||
    device.type === "Meter"
  ) {
    if (graphType === "Current") {
      return "" + status + " Ampere";
    } else if (graphType === "Voltage") {
      return "" + status + " Volts";
    } else if (graphType === "Power") {
      return "" + status + " KWatts";
    } else if (graphType === "Energy") {
      return "" + status + " Kw/Hr";
    } else if (graphType === "Load") {
      return "" + status + " %";
    }
  }
}

export function getDevice(hvalue, devices) {
  let temp = null;
  devices.map((dvalue) => {
    if (hvalue._id === dvalue.levelId) {
      temp = dvalue;
    }
  });
  return temp;
}

export function getDevices(hvalue, devices) {
  let temp = [];
  devices.map((dvalue) => {
    if (hvalue._id === dvalue.levelId) {
      temp.push(dvalue);
    }
  });
  return temp;
}

export function createDate() {
  let temp = moment().utc().add(5, "hours");
  return temp;
}

function toFahrenheit(celsius) {
  return (9 * celsius) / 5 + 32;
}
function toCelsius(fehrenheit) {
  return (5 * (fehrenheit - 32)) / 9;
}
function getType(input) {
  return {}.toString.call(input).slice(8, -1);
}
export function heatIndex(input) {
  if (arguments.length === 0) {
    throw new Error("Invalid Argument. Need at least one.");
  }
  if (getType(input) !== "Object") {
    throw new TypeError("Invalid Argument. Expecting 'Object'");
  }
  if (
    getType(input.temperature) !== "Number" ||
    getType(input.humidity) !== "Number"
  ) {
    throw new TypeError(
      "Invalid Argument. temperature and humidity must be 'Number'"
    );
  }
  var t = toFahrenheit(input.temperature) || 0,
    h = input.humidity || 0;
  if (input.fahrenheit) {
    t = input.temperature;
  }
  // Steadman's result
  var heatIndex = 0.5 * (t + 61 + (t - 68) * 1.2 + h * 0.094);
  // regression equation of Rothfusz is appropriate
  if (t >= 80) {
    var heatIndexBase =
      -42.379 +
      2.04901523 * t +
      10.14333127 * h +
      -0.22475541 * t * h +
      -0.00683783 * t * t +
      -0.05481717 * h * h +
      0.00122874 * t * t * h +
      0.00085282 * t * h * h +
      -0.00000199 * t * t * h * h;
    // adjustment
    if (h < 13 && t <= 112) {
      heatIndex =
        heatIndexBase -
        ((13 - h) / 4) * Math.sqrt((17 - Math.abs(t - 95)) / 17);
    } else if (h > 85 && t <= 87) {
      heatIndex = heatIndexBase + ((h - 85) / 10) * ((87 - t) / 5);
    } else {
      heatIndex = heatIndexBase;
    }
  }
  return input.fahrenheit ? heatIndex : toCelsius(heatIndex);
}

export function dataHandler(data) {
  let temp = data.substring(0, 6);
  data = data.substring(6);
  const bytes = CryptoJS.AES.decrypt(data, temp);
  let aa=bytes.toString(CryptoJS.enc.Utf8);
  // console.log(JSON.parse(zlib.inflateSync(Buffer.from(aa, 'base64')).toString()))
  return JSON.parse(JSON.parse(zlib.inflateSync(Buffer.from(aa, 'base64')).toString()));
}
