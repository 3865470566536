import React, { Component } from "react";
import { getdevicesByEventTypes } from "../shared/services/devices";
import * as moment from "moment";

import RequestInfo from "./strip/request-info";
import Description from "./weather-desc/description";
import Weather from "./weather/weather";
// import Header from "../components/header/header";
// import Footer from "../components/footer";
import { ListGroupItemHeading } from "reactstrap";
// import WeatherMap from "./weather/weatherMap";
// import WeatherSummary from "./weather/weatherSummary";


class WeatherContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      indication: true,
      device_type: [
        "WindDirection",
        "WindSpeed",
        "Gust",
        "Rain",
        "Illuminance",
        "UV",
        "Humidity",
        "Temperature",
      ],
    };
    // this.state = {
    //   coords: null
    // }
  }
  loadData = async () => {
    try {
      document.getElementById("root_title").innerHTML =
        "Linked Things - Weather";

      if (this.state.data == null) {
        const restCalls = this.state.device_type.map((type) => {
          return getdevicesByEventTypes(type);
        });
        let indication = !this.state.indication;
        await Promise.all(restCalls).then((response) => {
          if (response !== "Errror") {
            let obj = {};
            response.map((data) => {
              let device_name = data[0].deviceId.split("_");
              obj[device_name[2]] = {
                created: data[0].created,
                value: data[0].value,
              };
            });
            this.setState({
              data: obj,
              indication,
            });
          } else {
            // console.log("Wather-Container", restCalls);
          }
        });
      }
    } catch (err) {
      console.log("Error In Weather Conatiner", err);
    }
  };

  refreshData = () => {
    try {
      let check = null;
      const restCalls = this.state.device_type.map((type) => {
        return getdevicesByEventTypes(type);
      });
      console.log("Refresh Data", moment().format("HH:mm:ss"));
      let indication = !this.state.indication;

      Promise.all(restCalls).then((response) => {
        if (restCalls !== "Errror") {
          let obj = {};
          response.map((data) => {
            let device_name = data[0].deviceId.split("_");
            obj[device_name[2]] = {
              created: data[0].created,
              value: data[0].value,
            };
          });
          this.setState({
            data: obj,
            indication,
          });
        } else {
          // console.log("Wather-Container", restCalls);
        }
      });
    } catch (err) {
      console.log("Weather", err);
    }
  };

  interval = setInterval(() => {
    this.refreshData();
  }, 180000);
  // weatherCoords = (coords) => {
  //   this.setState({coords})
  // }
  render() {
    this.loadData();
    return (
      <React.Fragment>
        {/* <Header /> */}
        <Description />
        <Weather data={this.state.data} indication={this.state.indication} />
        <RequestInfo />
        {/* <Footer /> */}
      </React.Fragment>
    );
  }
}

export default WeatherContainer;
