// import React from 'react';
import React, { useState } from 'react';

import { Carousel } from 'react-bootstrap'

const Description = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <div className="gradient-primary2 p-5 my-0 text-light" style={{ textAlign: 'center', fontSize: '16px'}}>
            <h1 className="AQIHeading">Weather</h1>
            <Carousel activeIndex={index} onSelect={handleSelect} controls={false}>
                <Carousel.Item>
                    <div className="d-flex w-100" style={(window.screen.width < 850)?
                        {minHeight:'300px', margin: 'auto', fontSize: '14px'}:
                        {minHeight:'150px', margin: 'auto'}}
                        >
                        <div className="w-100 d-flex align-self-center justify-content-center">
                            <q>Pakistan is one of the worst hit countries due to climate change. Citizens of Karachi are also experience extreme weather conditions, the temperatures in July and August were unusually high and the rainfall was much higher in a much shorter time period. Some times weather changes within a few kilometers, calling for more local data collection to record the events and analyze to understand the changes.</q>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="d-flex w-100" style={(window.screen.width < 850)?
                        {minHeight:'300px', margin: 'auto'}:
                        {minHeight:'150px', margin: 'auto'}}>
                        <div className="w-100 d-flex align-self-center justify-content-center">
                            <q>This is Linked Things' humble effort to start recording the data for Karachi, hopefully one day this data will be useful for climate change researchers and policy makers.</q>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    );
};

export default Description;